import Cookies from 'js-cookie';

const api = '/siha-api/v1';
const base_api = '/siha-api';
const headers = {
  Accept: 'application/json',
};

function _do_api_call(
  relative_url,
  data,
  call_type,
  signal,
  auth_header = true,
  content_type = true,
) {
  return fetch(
    `${api}/${relative_url}`,
    _params(data, call_type, signal, auth_header, content_type),
  )
    .then(response => response.json())
    .then(responseData => {
      if (
        responseData['error'] &&
        responseData.hasOwnProperty('error') &&
        responseData['error']['message'] &&
        responseData['error']['message'].includes('auth')
      ) {
        Cookies.remove('clinician-token');
        return window.location.replace(`${process.env.PUBLIC_URL}/login`);
      } else {
        return new Promise(resolve => {
          return resolve(responseData);
        });
      }
    });
}

function _do_api_call_basic_auth(relative_url, username, password, call_type, data = {}) {
  return fetch(
    `${base_api}/${relative_url}`,
    _params_basic_auth(username, password, data, call_type),
  ).then(response => response.json());
}

function _params(data, call_type, signal, auth_header, content_type) {
  let params = {};
  if (content_type === false) {
    params = {
      method: call_type,
      headers: { ...headers },
      credentials: 'same-origin',
    };
  } else {
    params = {
      method: call_type,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    };
  }

  if (auth_header) params.headers.Authorization = 'Bearer ' + Cookies.get('clinician-token');
  if (call_type !== 'GET')
    if (content_type) params.body = JSON.stringify(data);
    else params.body = data;
  if (signal !== null) params = { ...params, signal };
  return params;
}

function _params_basic_auth(username, password, data, call_type) {
  let params = {
    method: call_type,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  };
  params.headers.Authorization = 'Basic ' + btoa(username + ':' + password);
  if (call_type !== 'GET') params.body = JSON.stringify(data);
  return params;
}

export const post = (relative_url, data, auth_header = true, content_type = true) =>
  _do_api_call(relative_url, data, 'POST', null, auth_header, content_type);
export const get_basic_auth = (relative_url, username, password, data = {}) =>
  _do_api_call_basic_auth(relative_url, username, password, 'GET');
export const delete_ = (relative_url, data) => _do_api_call(relative_url, data, 'DELETE', null);
export const get = (relative_url, signal) => _do_api_call(relative_url, {}, 'GET', signal);
export const put = (relative_url, data) => _do_api_call(relative_url, data, 'PUT', null);
