import React from 'react';
import { MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';
import PropTypes from 'prop-types';
import UpdatePasswordForm from '../components/UpdatePasswordForm';

const UpdatePasswordView = props => {
  return (
    <React.Fragment>
      <MDBContainer
        className="my-5 padding-t20  d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div className="d-flex justify-content-center align-items-center ">
          <MDBRow>
            <MDBCard className="w-auto" style={{ minWidth: '35rem' }}>
              <div className="header pt-3 grey lighten-2">
                <MDBRow className="d-flex justify-content-start">
                  <h3 className="deep-grey-text mt-3 mb-4 pb-1 mx-5">Log in - Update Password</h3>
                </MDBRow>
              </div>
              <MDBCardBody className="mx-4 mt-4">
                <UpdatePasswordForm
                  account={props.account}
                  onUpdatePasswordClick={props.onUpdatePasswordClick}
                />

                <div className="float-right">
                  <button
                    type="button"
                    className="link-button"
                    onClick={props.onUpdatePasswordView}
                  >
                    {' '}
                    Back To Login
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </div>
      </MDBContainer>
    </React.Fragment>
  );
};

UpdatePasswordView.propTypes = {
  onUpdatePasswordClick: PropTypes.func,
  account: PropTypes.object,
  onUpdatePasswordView: PropTypes.func,
};

export default UpdatePasswordView;
