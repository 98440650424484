import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact';
import { FaChartBar, FaUserInjured, FaUserFriends, FaUser } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../assets/images/siha-white.svg';

class Navbar extends Component {
  render() {
    const styleLoginMenuItems = !this.props.isLoggedIn ? {} : { display: 'none' };
    const styleLoggedInMenuItems = this.props.isLoggedIn ? {} : { display: 'none' };
    return (
      <div>
        <MDBNavbar className="bg-blue fixed-top" light expand="md">
          <MDBContainer className="text-center text-md-left" style={{ marginTop: '0px' }}>
            <MDBNavbarBrand>
              <NavLink to="/">
                <Logo style={{ height: '40px', marginRight: '100px' }} />
              </NavLink>
            </MDBNavbarBrand>
            <MDBNavbarNav left style={styleLoggedInMenuItems}>
              <MDBNavItem>
                <NavLink to="/overview" className="topnav-item">
                  <FaChartBar className="mr-2" />
                  Clinic Overview
                </NavLink>
              </MDBNavItem>
              <MDBNavItem>
                <NavLink to="/patients/all" className="topnav-item">
                  <FaUserInjured className="mr-2" /> Patients
                </NavLink>
              </MDBNavItem>
              <MDBNavItem>
                <NavLink to="/groups/all" className="topnav-item">
                  <FaUserFriends className="mr-2" /> Groups
                </NavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarToggler onClick={this.props.onNavBarDropdownClick} />
            <MDBCollapse isOpen={this.props.topNavDropdownCollapse} navbar>
              <MDBNavbarNav right>
                <MDBNavItem style={styleLoginMenuItems}>
                  <NavLink to="/login" className="login-nav-link">
                    Login
                  </NavLink>
                </MDBNavItem>
                <MDBNavItem style={styleLoggedInMenuItems}>
                  <MDBDropdown>
                    <MDBDropdownToggle className="white-text" nav caret>
                      <div className="d-none d-md-inline">
                        {' '}
                        <FaUser /> {this.props.username}
                      </div>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right>
                      <NavLink to="/my-account" className="nav-link">
                        <MDBDropdownItem>Account</MDBDropdownItem>
                      </NavLink>
                      <MDBDropdownItem onClick={this.props.onLogoutClick}>Logout</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>
    );
  }
}

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool,
  account: PropTypes.object,
  topNavDropdownCollapse: PropTypes.bool,
  onNavBarDropdownClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
};

export default Navbar;
