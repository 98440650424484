import React, { Component } from 'react';
import { MDBBtn, MDBAlert } from 'mdbreact';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const updatePasswordValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string().required('New password is required'),
  confirmPassword: Yup.string()
    .trim('The password cannot include leading and trailing spaces')
    .strict(true)
    .min(5, 'The password needs to be at least 5 characters long')
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match!")
    .required('Confirm password is required'),
});

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setStatus }) {
    this.props.onUpdatePasswordClick(values, setStatus);
  }

  render() {
    return (
      <Formik
        initialValues={{
          username: this.props.account.username,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={updatePasswordValidationSchema}
      >
        {({ status, errors, touched, submitCount }) => (
          <Form>
            {status && status.api_error_message && (
              <MDBAlert color="danger" dismiss>
                {status.api_error_message}
              </MDBAlert>
            )}
            {status === false && submitCount > 0 && (
              <MDBAlert color="danger" dismiss>
                Something went wrong. Please try again.
              </MDBAlert>
            )}
            {status && status.success_message && (
              <MDBAlert color="success" dismiss>
                {status.success_message}
              </MDBAlert>
            )}
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="username" style={{ display: 'block' }}>
                Username <sup>*</sup>
              </label>
              <Field type="text" name="username" className="form-control" />
              <ErrorMessage name="username" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="email" style={{ display: 'block' }}>
                Email <sup>*</sup>
              </label>
              <Field type="text" name="email" className="form-control" />
              <ErrorMessage name="email" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="currentPassword" style={{ display: 'block' }}>
                Current Password <sup>*</sup>
              </label>
              <Field type="password" name="currentPassword" className="form-control" />
              <ErrorMessage name="currentPassword" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="newPassword" style={{ display: 'block' }}>
                New Password <sup>*</sup>
              </label>
              <Field type="password" name="newPassword" className="form-control" />
              <ErrorMessage name="newPassword" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="conformPassword" style={{ display: 'block' }}>
                Repeat new password <sup>*</sup>
              </label>
              <Field type="password" name="confirmPassword" className="form-control" />
              <ErrorMessage name="confirmPassword" className="error" component="div" />
            </div>
            {touched.username && Object.keys(errors).length === 0 ? (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
              >
                Update Password
              </MDBBtn>
            ) : (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
                disabled
              >
                Update Password
              </MDBBtn>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

UpdatePasswordForm.propTypes = {
  onUpdatePasswordClick: PropTypes.func,
  account: PropTypes.object,
};

export default UpdatePasswordForm;
