import React, { Component } from 'react';
import * as SihaAPI from '../../../SihaAPI';
import LoginView from '../ui/LoginView';
import ResetPasswordView from '../ui/ResetPasswordView';
import UpdatePasswordView from '../ui/UpdatePasswordView';
import PropTypes from 'prop-types';

class Login extends Component {
  constructor(props) {
    super(props);
    this.resetPasswordView = this.resetPasswordView.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.updatePasswordView = this.updatePasswordView.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
  }
  state = {
    showResetPassword: false,
    showUpdatePassword: this.props.displayUpdatePasswordView,
  };
  resetPasswordView = () => {
    this.setState(prevState => ({
      ...prevState,
      showResetPassword: !this.state.showResetPassword,
    }));
  };

  updatePasswordView() {
    this.setState(prevState => ({
      ...prevState,
      showUpdatePassword: !this.state.showUpdatePassword,
    }));
  }

  resetPassword = () => {
    this.handleResetPasswordClick();
    this.resetPasswordView();
  };

  handleResetPasswordClick(values, setStatus) {
    const updatedUserFields = {
      username: values.username,
      email: values.email,
    };
    return SihaAPI.post('clinicians/forgot-password', updatedUserFields)
      .then(json => {
        if ('error' in json) {
          setStatus({ api_error_message: json.message });
        }
        if (Object.keys(json).length === 0) {
          setStatus({
            success_message: 'Successfully reset password. Check your email for the new password.',
          });
        }
      })
      .catch(error => {
        console.error('Error during reset-password:', error);
      });
  }

  handleUpdatePasswordClick = (values, setStatus) => {
    const updatedUserFields = {
      username: values.username,
      email: values.email,
      old_password: values.currentPassword,
      new_password: values.newPassword,
    };
    return SihaAPI.post('clinicians/reset-password', updatedUserFields)
      .then(json => {
        if ('error' in json) {
          setStatus({ api_error_message: json.message });
        }
        if (Object.keys(json).length === 0) {
          setStatus({ success_message: 'Successfully updated password.' });
          setTimeout(() => this.updatePasswordView(), 3000);
        }
      })
      .catch(error => {
        console.error('Error during reset-password:', error);
      });
  };

  render() {
    if (this.state.showResetPassword) {
      return (
        <ResetPasswordView
          account={this.props.account}
          onResetPasswordClick={this.handleResetPasswordClick}
          onResetPasswordView={this.resetPasswordView}
        />
      );
    } else if (this.state.showUpdatePassword) {
      return (
        <UpdatePasswordView
          account={this.props.account}
          onUpdatePasswordClick={this.handleUpdatePasswordClick}
          onUpdatePasswordView={this.updatePasswordView}
        />
      );
    } else {
      return (
        <LoginView
          isLoggedIn={this.props.isLoggedIn}
          account={this.props.account}
          onLogin={this.props.onLogin}
          clinicNames={this.props.clinicNames}
          onClinicSelectChange={this.props.onClinicSelectChange}
          selectedClinic={this.props.selectedClinic}
          onResetPasswordView={this.resetPasswordView}
          onUpdatePasswordView={this.updatePasswordView}
          dataTypesExist={this.props.dataTypesExist}
        />
      );
    }
  }
}

Login.propTypes = {
  isLoggedIn: PropTypes.bool,
  account: PropTypes.object,
  clinicNames: PropTypes.array,
  onClinicSelectChange: PropTypes.func,
  selectedClinic: PropTypes.object,
  onLogin: PropTypes.func,
  dataTypesExist: PropTypes.bool,
};

export default Login;
