import React from 'react';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';
import PropTypes from 'prop-types';

const ResetPasswordView = props => {
  return (
    <React.Fragment>
      <MDBContainer
        className="my-5 padding-t20  d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div className="d-flex justify-content-center align-items-center ">
          <MDBRow>
            <MDBCard className="w-auto" style={{ minWidth: '35rem' }}>
              <div className="header pt-3 grey lighten-2">
                <MDBRow className="d-flex justify-content-start">
                  <h3 className="deep-grey-text mt-3 mb-4 pb-1 mx-5">Log in - Reset Password</h3>
                </MDBRow>
              </div>
              <MDBCardBody className="mx-4 mt-4">
                <ResetPasswordForm
                  account={props.account}
                  onResetPasswordClick={props.onResetPasswordClick}
                />

                <div className="float-right">
                  <button type="button" className="link-button" onClick={props.onResetPasswordView}>
                    {' '}
                    Back To Login
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </div>
      </MDBContainer>
    </React.Fragment>
  );
};

ResetPasswordView.propTypes = {
  onResetPasswordClick: PropTypes.func,
  account: PropTypes.object,
  onResetPasswordView: PropTypes.func,
};

export default ResetPasswordView;
