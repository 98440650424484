import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { ReactComponent as Logo } from '../assets/images/siha-blue.svg';

class ErrorHandler extends Component {
  // This component is an error boundary, used to render a fallback UI after an uncaught js error has been thrown. FMI:https://reactjs.org/docs/error-boundaries.html
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <MDBContainer className="padding-t70">
        <MDBRow className="my-5">
          <MDBCol md="3" />
          <MDBCol md="6" className="text-center">
            <Logo style={{ width: '300px' }} />
            <h2 className="h1-responsive font-weight-bold my-5">Oops! Something went wrong.</h2>
            <p className="lead grey-text w-responsive mx-auto mb-5">Try again in a few seconds.</p>
            <MDBBtn
              gradient="blue"
              type="button"
              className="btn-block z-depth-2"
              onClick={() => window.location.reload()}
            >
              Home
            </MDBBtn>
          </MDBCol>
          <MDBCol md="3" />
        </MDBRow>
      </MDBContainer>
    ) : (
      this.props.children
    );
  }
}

export default ErrorHandler;
